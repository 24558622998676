import { Delete, Edit } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { DeleteDialog, ThestralDataGrid } from 'components/thestral';
import { CLUBS_URL, EMPTY } from 'const';
import { useFetch } from 'func';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Club, Roles } from 'types';
import { ClubEditDialog } from '../ClubEditDialog';
import { Button } from 'components/ui';
import { Trans } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { ClubInfoDialog } from '../ClubInfoDialog';

export function ClubDataGrid() {
  const auth = useAuth();

  const { data, triggerRefetch } = useFetch<Club[]>(`${CLUBS_URL}`);
  const [clubs, setClubs] = useState<Club[]>();
  const [club, setClub] = useState<Club>();
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [viewOpen, setViewOpen] = useState<boolean>(false);

  function hasAnyRole(allowed: Array<string>) {
    if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.some((r) => allowed.includes(r)) ?? false;
    }

    return true;
  }

  const isAdmin = hasAnyRole(['ADMIN']);

  useEffect(() => {
    if (data) {
      setClubs(data);
      setLoading(false);

      if (club) {
        const updatedClub = data.find((obj) => obj.id === club.id);

        setClub(updatedClub);
      }
    }
  }, [data]);

  const onDelete = (event, club: Club) => {
    event.stopPropagation();
    setDeleteOpen(true);
    setClub(club);
  };

  const onRowClick = (elem) => {
    setClub(elem?.row);
    setViewOpen(true);
  };

  const onEdit = (event, club: Club) => {
    event.stopPropagation();
    setClub(club);
    setEditOpen(true);
  };

  const handleClose = () => {
    setViewOpen(false);
    setEditOpen(false);
    setClub(undefined);
  };

  return (
    <>
      {loading && <CircularProgress />}

      {club && (
        <DeleteDialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          triggerRefetch={triggerRefetch}
          endpoint={`${CLUBS_URL}/${club?.id}`}
          i18nBase='Components.Admin.Clubs'
        />
      )}

      {editOpen && (
        <ClubEditDialog
          open={editOpen}
          onClose={handleClose}
          isAdmin={isAdmin}
          setEditOpen={setEditOpen}
          triggerRefetch={triggerRefetch}
          club={club}
        />
      )}

      {viewOpen && (
        <ClubInfoDialog
          open={viewOpen}
          onClose={handleClose}
          triggerRefetch={triggerRefetch}
          club={club}
        />
      )}

      {isAdmin && (
        <Button
          onClick={() => setEditOpen(true)}
          color='success'
          text={<Trans i18nKey='Components.Admin.Clubs.ClubNew' />}
          sx={{ marginBottom: '10px' }}
        />
      )}
      <ThestralDataGrid
        rows={clubs || EMPTY}
        columnVisibilityModel={{
          actions: isAdmin,
        }}
        columns={[
          {
            field: 'name',
            headerName: t('Generic.Name'),
            sortable: true,
            flex: 2,
            valueGetter: (_, row) => row?.name,
          },
          {
            field: 'description',
            headerName: t('Generic.Description'),
            sortable: true,
            flex: 5,
            valueGetter: (_, row) => row?.description,
          },
          {
            field: 'actions',
            headerName: 'Aktionen',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params: GridRenderCellParams) => {
              return loading ? (
                <CircularProgress />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    justifyContent: 'end',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <IconButton onClick={(event) => onDelete(event, params.row)}>
                    <Tooltip title={t('Components.Admin.Clubs.Delete')}>
                      <Delete color='primary' />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={(event) => onEdit(event, params.row)}>
                    <Tooltip title={t('Components.Admin.Clubs.ClubEdit')}>
                      <Edit color='primary' />
                    </Tooltip>
                  </IconButton>
                </Box>
              );
            },
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        rowHeight={48}
        hideSearch
        hideFooter
        hideFooterPagination
        pageSizeOptions={[5, 10]}
        loading={loading}
        onRowClick={onRowClick}
      />
    </>
  );
}
