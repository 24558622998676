import { DataGrid, GridColDef, GridColumnVisibilityModel, GridInitialState, GridToolbar, GridValidRowModel } from '@mui/x-data-grid';
import { styled, SxProps } from '@mui/material/styles';
import { t } from 'i18next';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { MutableRefObject } from 'react';

import { ThestralDataGridOverlay } from 'components/thestral';
import { DataGridPagination } from 'components/ui';

export const ThestralDataGridStyle = styled(DataGrid)(() => ({
    fontFamily: ['Neuton'].join(','),
    backgroundColor: '#1E1E1E',
    fontSize: '0.875rem',
    border: 0,
    borderColor: '#2e2e2e',
    display: 'flex',

    '& .MuiDataGrid-row': {
        '--rowBorderColor': '#777',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#1e1e1e',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        display: 'inherit',
        alignItems: 'flex-start',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: '#616161',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: '#616161',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#1E1E1E',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 0,
    },
    '& .MuiDataGrid-sortIcon': {
        fontWeight: 700,
        color: 'rgba(255, 255, 255, 0.7) !important',
        opacity: 1,
    },
    '& .MuiDataGrid-menuIcon': {
        display: 'none',
    },
    '& .MuiDataGrid-filterIcon': {
        display: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: 'rgba(255, 255, 255, 0.7) !important',
        fontSize: '1rem',
        height: '48px',
        fontWeight: 700,
    },
    '& .MuiDataGrid-columnHeaderTitle:hover': {
        color: 'white !important',
    },
    '& .MuiDataGrid-withBorderColor': {
        borderColor: 'rgba(255, 255, 255, 0.25)',
    },
    '& .MuiDataGrid-overlay': {
        backgroundColor: 'transparent',
    },
}));

type Props = {
    columns: GridColDef<GridValidRowModel>[];
    rows: GridValidRowModel[];
    initialState?: GridInitialState;
    loading?: boolean;
    onRowClick?: (elem) => void;
    sx?: SxProps;
    hideFooter?: boolean;
    hideFooterPagination?: boolean;
    pageSizeOptions?: number[];
    rowHeight?: number;
    apiRef?: MutableRefObject<GridApiCommunity>;
    hideSearch?: boolean;
    rowId?: (any) => string;
    columnVisibilityModel?: GridColumnVisibilityModel;
};

export function ThestralDataGrid(props: Readonly<Props>) {
    return (
        <ThestralDataGridStyle
            apiRef={props.apiRef}
            rows={props.rows}
            columns={props.columns}
            loading={props.loading}
            initialState={props.initialState}
            onRowClick={props.onRowClick}
            getRowId={props.rowId}
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            disableColumnMenu
            disableVirtualization
            columnVisibilityModel={props.columnVisibilityModel}
            sx={{
                ...props.sx,
                height: 'auto',
            }}
            slots={{
                pagination: DataGridPagination,
                toolbar: GridToolbar,
                noRowsOverlay: ThestralDataGridOverlay,
            }}
            localeText={{
                toolbarQuickFilterPlaceholder: t('Generic.Search'),
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: !props.hideSearch,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    sx: {
                        '& .MuiInputBase-input': {
                            color: 'primary.main',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'primary.main',
                        },
                        '& .MuiInput-underline:before': {
                            borderBottomColor: '#777',
                        },
                        '& .MuiInput-underline': {
                            borderBottomColor: '#777',
                        },
                        '& .MuiInput-underline:hover:before': {
                            borderBottomColor: '#e0e0e0',
                        },
                        '& .MuiInput-underline:after': {
                            color: '#777',
                        },
                        '& .MuiDataGrid-toolbarQuickFilter': {
                            color: 'primary.main',
                        },
                    },
                },
            }}
            rowHeight={props.rowHeight ?? 48}
            hideFooterSelectedRowCount
            hideFooter={props.hideFooter}
            hideFooterPagination={props.hideFooterPagination}
            pageSizeOptions={props.pageSizeOptions}
        />
    );
}
