import { Box } from '@mui/system';
import { Headline } from 'components/thestral';
import { ClubDataGrid } from 'components/thestral/ClubDataGrid';

import { t } from 'i18next';

export function Clubs() {
  return (
    <Box sx={{ width: '100%' }}>
      <Headline title={t('Components.Admin.Clubs.Clubs')} />

      <ClubDataGrid />
    </Box>
  );
}
