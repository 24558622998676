import { useEffect, useState } from 'react';
import { t } from 'i18next';

import { Dialog } from 'components/ui';
import { DeleteDialog, ThestralDataGrid } from 'components/thestral';

import { WAITINGLIST_URL } from 'const';
import { useFetch } from 'func';
import { WaitingListPerson } from 'types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
};

export function WaitingListDialog(props: Readonly<Props>) {
  const { data, triggerRefetch } =
    useFetch<WaitingListPerson[]>(WAITINGLIST_URL);
  const [waitingList, setWaitingList] = useState<WaitingListPerson[]>();
  const [person, setPerson] = useState<WaitingListPerson>();
  const [deleteOpen, setDeleteOpen] = useState<boolean>();

  useEffect(() => {
    if (data) {
      setWaitingList(data);
    }
  }, [props.open, data]);

  const onDelete = (event, person) => {
    event.stopPropagation();
    setDeleteOpen(true);
    setPerson(person);
  };

  return (
    <>
      {person && (
        <DeleteDialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          triggerRefetch={triggerRefetch}
          endpoint={`${WAITINGLIST_URL}/${person?.id}`}
          i18nBase='Components.Admin.Users'
        />
      )}
      <Dialog
        open={props.open}
        onClose={props.onClose}
        title={props.title}
        content={
          <ThestralDataGrid
            hideSearch
            hideFooter
            hideFooterPagination
            sx={{
              marginTop: '40px',
              width: 'auto',
              minWidth: '300px',
            }}
            rows={waitingList ?? []}
            columns={[
              { field: 'nickname', headerName: t('Generic.Nickname'), flex: 1 },
              { field: 'pronouns', headerName: t('Generic.Pronouns'), flex: 1 },
              { field: 'email', headerName: t('Generic.Email'), flex: 1 },
              {
                field: 'actions',
                headerName: 'Aktionen',
                flex: 1,
                align: 'right',
                headerAlign: 'right',
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'end',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <IconButton
                        onClick={(event) => onDelete(event, params.row)}
                      >
                        <Tooltip title={t('Generic.DeleteUser')}>
                          <Delete color='primary' />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  );
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
