import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { usePrevious } from 'react-use';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Edit } from '@mui/icons-material';
import { InstagramEmbed } from 'react-social-media-embed';

import {
  GridHeadline,
  TabContent,
  ThestralCheckbox,
} from 'components/thestral';
import { Button, Dialog, LinkText, TextField } from 'components/ui';

import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { CONTENT_URL, HTTP_METHOD, WAITINGLIST_URL } from 'const';
import { JsonContext } from 'contexts';
import { Roles, ThestralContent } from 'types';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const validationSchema = yup.object({
  email: yup.string().email().required(t('Components.Register.Required')),
  pronouns: yup.string().required(t('Components.Register.Required')),
  nickname: yup.string().required(t('Components.Register.Required')),
  legal: yup
    .boolean()
    .oneOf([true], t('Components.Register.DataProtection.Required')),
});

export function WaitingList() {
  const fetchFromBackend = useFetchFromBackend(true);
  const saveToBackend = useFetchFromBackend();
  const nav = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { data, triggerRefetch } = useFetch<ThestralContent>(
    `${CONTENT_URL}/slug/public/who-are-we`
  );
  const [json, setJson] = useState();
  const jsonContextValue = useMemo(() => ({ json, setJson }), [json, setJson]);
  const auth = useAuth();
  const [openEdit, setOpenEdit] = useState<boolean>();
  const prevJson = usePrevious(json);

  useEffect(() => {
    if (data) {
      setJson(JSON.parse(data?.content));
    }
  }, [data]);

  const onSubmit = (values) => {
    setLoading(true);
    const body = JSON.stringify(values);
    fetchFromBackend(WAITINGLIST_URL, { method: HTTP_METHOD.POST, body })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Components.WaitingList.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.status === 409) {
          showSnackbar(`${t('Components.WaitingList.EmailExists')}`, severity);
        } else if (response.status === 200) {
          setTimeout(() => nav('/rules/world-building'), 3000);
          showSnackbar(
            `${t('Components.WaitingList.Submit')} ${result}`,
            severity
          );
        } else {
          showSnackbar(
            `${t('Components.WaitingList.Submit')} ${result}`,
            severity
          );
        }
      })
      .catch((error) => {
        showSnackbar(
          `${t('Components.WaitingList.Submit')} ${error.text} (${
            error.statusCode
          })`,
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      nickname: '',
      pronouns: '',
      legal: false,
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
  });

  const isAdmin = () => {
    if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.some((r) => ['ADMIN'].includes(r)) ?? false;
    }
    return false;
  };

  const onSave = () => {
    if (json !== prevJson) {
      const body = JSON.stringify({
        title: 'who-are-we',
        content: JSON.stringify(json),
      });
      saveToBackend(`${CONTENT_URL}/${data.id}`, {
        method: HTTP_METHOD.PUT,
        body,
      })
        .then((response) => {
          if (response.ok) {
            triggerRefetch();
          }
        })
        .catch((error) => console.error(error));
    }
    setOpenEdit(false);
  };

  function content(): ReactNode {
    return (
      <Editor
        history
        value={json}
        onChange={(newJson) => setJson(newJson)}
        expanded
      />
    );
  }

  return (
    <Box
      sx={{
        zIndex: 2,
        position: 'relative',
        width: 'auto',
        maxWidth: '1200px',
        flexDirection: 'column',
        top: '50px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <Typography
            variant='h1'
            sx={{
              fontSize: '3.5rem',
              fontWeight: '700',
            }}
          >
            <Trans i18nKey='Generic.Title' />
          </Typography>
          <GridHeadline title={t('Components.WaitingList.Caption')} />

          <Box sx={{ marginTop: '50px' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  id='email'
                  label={t('Components.WaitingList.Email')}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.email)}
                  helperText={formik.errors.email as string}
                />
                <TextField
                  id='nickname'
                  label={t('Components.WaitingList.Nickname')}
                  value={formik.values.nickname}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.nickname)}
                  helperText={formik.errors.nickname as string}
                />
                <TextField
                  id='pronouns'
                  label={t('Components.WaitingList.Pronouns')}
                  value={formik.values.pronouns}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.pronouns)}
                  helperText={formik.errors.pronouns as string}
                />

                <Typography
                  variant='caption'
                  component='div'
                  sx={{ marginTop: '30px' }}
                >
                  <Trans
                    i18nKey='Components.WaitingList.Legal'
                    components={{
                      url: <LinkText to='/privacy' target='_blank' />,
                    }}
                  />
                </Typography>
                <ThestralCheckbox
                  id='legal'
                  text={t('Components.Register.DataProtection.Checkbox')}
                  checked={formik.values.legal}
                  onChange={(event) =>
                    formik.setFieldValue('legal', event.target.checked)
                  }
                  sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                />
                {Boolean(formik.errors.legal) && (
                  <Typography color='error' component='div' variant='caption'>
                    {typeof formik.errors.legal === 'string' &&
                      formik.errors.legal}
                  </Typography>
                )}

                <Button
                  color='success'
                  onClick={formik.submitForm}
                  sx={{ marginTop: '20px' }}
                  text={t('Generic.Enroll')}
                />
              </form>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <JsonContext.Provider value={jsonContextValue}>
            {isAdmin() && (
              <IconButton
                aria-label='edit'
                onClick={() => setOpenEdit(true)}
                sx={{ float: 'right' }}
              >
                <Edit sx={{ color: '#eab354' }} />
              </IconButton>
            )}
            <TabContent name='who-are-we' />
          </JsonContext.Provider>

          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <InstagramEmbed url='//instagram.com/thestrallarp' width={350} />
          </Box>
        </Grid>
      </Grid>
      {openEdit && (
        <Dialog
          open={openEdit}
          onClose={onSave}
          title={t('Generic.Edit')}
          content={content()}
          sx={{
            '.jsoneditor': { height: '750px' },
          }}
        />
      )}
    </Box>
  );
}
