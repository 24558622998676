import { t } from 'i18next';
import { Box, Divider, List } from '@mui/material';
import {
  Checkroom,
  Diversity3,
  Gavel,
  Groups,
  Home,
  Person,
  School,
} from '@mui/icons-material';

import { MenuItem } from 'components/ui';
import { Earth } from 'mdi-material-ui';

type Props = {
  onClick: () => void;
};

export function BurgerItems(props: Readonly<Props>) {
  return (
    <Box sx={{ width: '256px' }}>
      <List>
        <Divider sx={{ borderColor: '#ffffff1f' }} />
        <MenuItem
          icon={<Home />}
          text={t('Generic.Home')}
          to='/'
          onClick={props.onClick}
        />

        <Divider sx={{ borderColor: '#ffffff1f' }} />
        <MenuItem
          icon={<Person />}
          text={t('Components.Profile.Title')}
          to='/profile'
          onClick={props.onClick}
        />
        <MenuItem
          icon={<Checkroom />}
          text={t('Components.Drapery.Title')}
          to='/drapery'
          onClick={props.onClick}
        />

        <Divider sx={{ borderColor: '#ffffff1f' }} />
        <MenuItem
          icon={<Groups />}
          text={t('Components.PlayerCharacters.Title')}
          to='/characters'
          onClick={props.onClick}
        />
        <MenuItem
          icon={<School />}
          text={t('Components.NonPlayerCharacters.Title')}
          to='/npcs'
          onClick={props.onClick}
        />
        <MenuItem
          icon={<Diversity3 />}
          text={t('Components.Admin.Clubs.Clubs')}
          to='/clubs'
          onClick={props.onClick}
        />

        <Divider sx={{ borderColor: '#ffffff1f' }} />
        <MenuItem
          icon={<Gavel />}
          text={t('Components.Rules.Title')}
          to='/rules'
          onClick={props.onClick}
        />
        <MenuItem
          icon={<Earth />}
          text={t('Components.LoggedIn.Sections.World')}
          to='/rules/world-building'
          onClick={props.onClick}
        />

        <Divider sx={{ borderColor: '#ffffff1f' }} />
      </List>
    </Box>
  );
}
