import { CircularProgress, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { CHARACTERS_URL, CLUBS_URL, HTTP_METHOD, NPCS_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Character, CharacterRevision, Club } from 'types';

import { Trans } from 'react-i18next';
import { Button, Dialog, SelectField, TextField } from 'components/ui';

type Props = {
  open: boolean;
  onClose: () => void;
  triggerRefetch?: () => void;
  club?: Club;
  isAdmin?: boolean;
  isLeader?: boolean;
  setEditOpen: (argument) => void;
};

export function ClubEditDialog(props: Readonly<Props>) {
  const { data: npcData } = useFetch<Character[]>(
    `${NPCS_URL}?npcType=TEACHER`
  );
  const { data: characterData } = useFetch<CharacterRevision[]>(CHARACTERS_URL);

  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [admins, setAdmins] = useState<number[]>([]);
  const [teacher, setTeacher] = useState<number>();
  const [npc, setNPC] = useState<Character[]>();
  const [error, setError] = useState(false);
  const [members, setMembers] = useState<number[]>([]);
  const [characters, setCharacters] = useState<Character[]>();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (props?.club) {
      const adminIds = props.club?.admins?.map((admin) => admin.id) || [];
      const memberIds = props.club?.members?.map((member) => member.id) || [];
      setAdmins(adminIds);
      setMembers(memberIds);
      setName(props.club?.name);
      setTeacher(props?.club?.teacher?.id || undefined);
      setDescription(props?.club?.description);
    }

    if (npcData && characterData) {
      const playerCharacters = characterData.map(
        (item) => item.playerCharacter
      );

      setNPC(npcData);
      setCharacters(playerCharacters);
      setLoading(false);
    }
  }, [props.club, npcData, characterData]);

  const onSaveClub = async () => {
    setError(false);
    setLoading(true);
    if (admins.length < 1 || !teacher || !name) {
      setError(true);
      setLoading(false);
      return;
    }

    const createFormObject = (props) => {
      if (props.isAdmin && props.isLeader) {
        return {
          name,
          admins,
          teacher,
          description,
          members,
        };
      }

      if (props.isAdmin) {
        return {
          name,
          admins,
          teacher,
        };
      }

      return {
        description,
        members,
      };
    };

    const formObject = createFormObject(props);
    const body = JSON.stringify(formObject);

    const isUpdating = props.club;
    const url = isUpdating ? `${CLUBS_URL}/${props.club?.id}` : CLUBS_URL;
    const method = isUpdating ? HTTP_METHOD.PUT : HTTP_METHOD.POST;
    const i18nKey = isUpdating
      ? 'Components.Admin.Clubs.ClubUpdate'
      : 'Components.Admin.Clubs.ClubNew';

    try {
      const response = await fetchFromBackend(url, { body, method });

      const severity = response.ok ? 'success' : 'error';
      const result = response.ok
        ? t('Generic.Successful')
        : `${t('Generic.Failed')} (${response.status})`;

      if (response.ok) {
        props.triggerRefetch();
        props.setEditOpen(false);
      }

      showSnackbar(`${t(i18nKey)} ${result}`, severity);
    } catch (error) {
      showSnackbar(`${t(i18nKey)} ${t('Generic.Failed')}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Dialog
          open={props.open}
          onClose={props.onClose}
          content={
            <>
              <form>
                {props.isAdmin && (
                  <Grid container gap={2} sx={{ marginBottom: '20px' }}>
                    <Grid md={3} xs={12}>
                      <TextField
                        id='name'
                        label={t('Generic.Name')}
                        onChange={(e) => setName(e.target.value)}
                        error={error && !name}
                        helperText={
                          error && !name
                            ? t('Components.Admin.Clubs.Required')
                            : ''
                        }
                        value={name}
                        sx={{ marginTop: '0px' }}
                        disabled={!props.isAdmin}
                      />
                    </Grid>
                    <Grid md={3} xs={12}>
                      <SelectField
                        numbered
                        label={t('Components.Admin.Clubs.Leader')}
                        id='admins'
                        multiple
                        numberedList={characters}
                        numberedValue={admins}
                        onChange={(e) => setAdmins(e.target.value)}
                        error={error && admins?.length < 1}
                        errorMsg={t('Components.Admin.Clubs.LeaderError')}
                        disabled={!props.isAdmin}
                      />
                    </Grid>
                    <Grid md={3} xs={12}>
                      <SelectField
                        numbered
                        label={t('Components.Admin.Clubs.Teacher')}
                        id='teacher'
                        numberedList={npc}
                        numberedValue={teacher}
                        onChange={(e) => setTeacher(e.target.value)}
                        error={error && !teacher}
                        errorMsg={t('Components.Admin.Clubs.ApproverError')}
                        disabled={!props.isAdmin}
                      />
                    </Grid>
                  </Grid>
                )}

                {props.isLeader && (
                  <Grid container gap={2}>
                    <Grid md={12} xs={6}>
                      <SelectField
                        numbered
                        label={t('Components.Admin.Clubs.Members')}
                        id='members'
                        multiple
                        numberedList={characters}
                        numberedValue={members}
                        onChange={(e) => setMembers(e.target.value)}
                        errorMsg={t('Components.Admin.Clubs.MemberError')}
                      />
                    </Grid>

                    <Grid xs={6} md={12}>
                      <TextField
                        id='description'
                        multiline={true}
                        rows={5}
                        label={t('Generic.Description')}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                    </Grid>
                  </Grid>
                )}
              </form>
            </>
          }
          actions={
            <Button
              onClick={onSaveClub}
              color='success'
              text={<Trans i18nKey='Generic.Save' />}
            />
          }
          title={
            props.club ? props.club.name : t('Components.Admin.Clubs.ClubNew')
          }
        />
      )}
    </>
  );
}
