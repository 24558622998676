import { Box, Chip, Paper, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { SelectField } from '../SelectField';
import { Button } from '../Button';
import { t } from 'i18next';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { CHARACTERS_URL, HTTP_METHOD, THESTRAL_URL } from 'const';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { GiCastle, GiCastleRuins } from 'react-icons/gi';
import { ThestralAll } from 'types';

type Props = {
  setFilteredCharacters?: (argument) => void;
  setLoading?: (argument) => void;
  loading?: boolean;
};

export function SearchBar(props: Readonly<Props>): ReactNode {
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [filter, setFilter] = useState([]);
  const [secondaryCategory, setSecondaryCategory] = useState([]);

  const { showSnackbar } = useSnackbar();
  const fetchFromBackend = useFetchFromBackend();
  const { data: response } = useFetch<ThestralAll>(`${THESTRAL_URL}/all`);

  const [query, setQuery] = useState([]);

  const data = {
    House: { backend: 'house', subCat: response?.House },
    AgeGroup: { backend: 'ageGroup', subCat: response?.AgeGroup },
    SchoolDuties: { backend: 'schoolDuty', subCat: response?.SchoolDuty },
    FavoriteSubject: {
      backend: 'favoriteSubject',
      subCat: response?.Subject,
      emote: <ThumbUpOffAltIcon />,
    },
    LeastFavoriteSubject: {
      backend: 'leastFavoriteSubject',
      subCat: response?.Subject,
      emote: <ThumbDownOffAltIcon />,
    },
    SchoolWhileWar: {
      backend: 'schoolWhileWar',
      subCat: response?.School,
      emote: <GiCastleRuins />,
    },
    SchoolBeforeWar: {
      backend: 'schoolBeforeWar',
      subCat: response?.School,
      emote: <GiCastle />,
    },
    Benefit: { backend: 'benefit', subCat: response?.Benefits },
    AssignedPlot: { backend: 'plot', subCat: response?.Plot },
    Breed: { backend: 'breed', subCat: response?.Breed },
    Quidditch: { backend: 'quidditch', subCat: response?.Quidditch },
  };

  const categories = Object.keys(data);

  useEffect(() => {
    if (filteredCategories.length === 0) {
      setFilteredCategories(categories);
    }
  }, [filteredCategories]);

  function handleSetCategory(e) {
    e.preventDefault();
    setSecondaryCategory([]);
    setCategory([e.target.value]);
    switch (e.target.value) {
      case 'SchoolBeforeWar':
        setFilter(['School']);
        break;
      case 'SchoolWhileWar':
        setFilter(['School']);
        break;
      case 'FavoriteSubject':
        setFilter(['Subject']);
        break;
      case 'LeastFavoriteSubject':
        setFilter(['Subject']);
        break;
      case 'Benefit':
        setFilter(['Benefits']);
        break;
      case 'AssignedPlot':
        setFilter(['Plot']);
        break;
      case 'SchoolDuties':
        setFilter(['SchoolDuty']);
        break;
      default:
        setFilter([e.target.value]);
    }
  }

  function handleSetSecondaryCategory(e) {
    e.preventDefault();
    setSecondaryCategory([e.target.value]);
    const curCategories = query.map((e) => e.cat);
    if (curCategories.includes(category[0])) {
      const newQuery = query.map((q) => {
        if (q.cat === category[0]) {
          return {
            cat: category[0],
            backendcat: data[category[0]].backend,
            subcat: e.target.value,
            enum: `Enum.${filter[0]}.${e.target.value}`,
            emote: data[category[0]].emote ? data[category[0]].emote : null,
          };
        } else {
          return q;
        }
      });

      setQuery(newQuery);
    } else {
      const newQuery = query;
      newQuery.push({
        cat: category[0],
        backendcat: data[category[0]].backend,
        subcat: e.target.value,
        enum: `Enum.${filter[0]}.${e.target.value}`,
        emote: data[category[0]].emote ? data[category[0]].emote : null,
      });
      setQuery(newQuery);
    }

    const newCategories = filteredCategories.filter((e) => e !== category[0]);
    setFilteredCategories(newCategories);
  }

  async function handleSearch() {
    const searchQuery = query.map((e) => {
      return `${e.backendcat}=${e.subcat}`;
    });
    const queryString = searchQuery.join('&').trim();
    props.setLoading(true);
    props.setFilteredCharacters([]);
    fetchFromBackend(`${CHARACTERS_URL}/search?${queryString}`, {
      method: HTTP_METHOD.GET,
    })
      .then((response) => {
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        const severity = response.ok ? 'success' : 'error';
        props.setLoading(false);
        response.json().then((res) => {
          props.setFilteredCharacters(res);
        });

        showSnackbar(`${t('Generic.SearchSnack')} ${result}`, severity);
      })
      .catch((error) => {
        console.error(error);
        showSnackbar(`${t('Generic.SearchError')}`, error);
      })
      .finally(() => props.setLoading(false));
  }

  const handleDelete = (cat) => {
    setQuery(() => query.filter((e) => e.cat !== cat));
    const newCategories = filteredCategories;
    newCategories.push(cat);
    setFilteredCategories(newCategories);
  };

  function handleClearSearch() {
    setCategory([]);
    setSecondaryCategory([]);
    setFilteredCategories([]);
    setFilter([]);
    setQuery([]);
    props.setFilteredCharacters(undefined);
  }

  return (
    <Box
      sx={{
        width: 'auto',
      }}
    >
      {query.length > 0 && (
        <Paper
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            border: '1px solid #aeaeae',
            bordeRadius: '10px',
            padding: '10px',
            backgroundColor: 'transparent',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {query?.map((query) => (
            <Chip
              color='secondary'
              key={query}
              label={t(`${query.enum}`)}
              onDelete={() => handleDelete(query.cat)}
              icon={query.emote}
            />
          ))}
        </Paper>
      )}
      <Box
        sx={{
          width: '100%',
          border: '1px solid grey',

          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '30px',
          alignItems: 'start',
          gap: '10px',
          padding: '5px',
          '@media(max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'start',
            gap: '5px',
          },
        }}
      >
        <Typography>Suche:</Typography>
        <Box
          sx={{
            flex: 1,
            width: '100%',
            '@media(max-width: 600px)': {
              width: '100%',
            },
          }}
        >
          <SelectField
            id='query-category'
            label='Kategorie'
            value={category[0]}
            list={filteredCategories}
            placeholder='Kategorie auswählen'
            i18nKey='Components.PlayerCharacters'
            onChange={(e) => handleSetCategory(e)}
            disabled={props.loading}
          />
        </Box>
        {category.length > 0 && (
          <Box
            sx={{
              flex: 1,
              width: '100%',
              '@media(max-width: 600px)': {
                width: '100%',
              },
            }}
          >
            <SelectField
              id='query-secondary-category'
              label='Unterkategorie'
              value={secondaryCategory}
              list={response[filter[0]]}
              i18nKey={`Enum.${filter[0]}`}
              onChange={(e) => handleSetSecondaryCategory(e)}
              disabled={props.loading}
            />
          </Box>
        )}
        {secondaryCategory.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Button
              color='primary'
              onClick={handleSearch}
              text={t('Generic.SearchShort')}
              sx={{
                '@media(max-width: 600px)': {
                  width: '100%',
                },
              }}
            />
            <Button
              color='error'
              onClick={handleClearSearch}
              text={t('Generic.Delete')}
              sx={{
                '@media(max-width: 600px)': {
                  width: '100%',
                },
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
