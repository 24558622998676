import { useMemo, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme, oidcConfig, TraceProvider } from 'config';

import {
  CharacterApproval,
  DraperyApproval,
  Home,
  Layout,
  NonPlayerCharacters,
  PlayerCharacters,
  PlayerDrapery,
  Profile,
  Register,
  Rules,
  Admin,
  Signup,
  SignupApproval,
  WaitingList,
  PrivacyImprint,
} from 'pages';

import { CodeOfConduct, RulesWorld, RulesIT, RulesDrapery } from 'pages/Rules';

import { User } from 'types';
import { UserContext } from 'contexts';

import './App.css';
import { LandingPage } from './pages/Home/LandingPage';
import { NotFound } from './pages/NotFound';
import { Clubs } from './pages/Clubs';

export function App() {
  const [user, setUser] = useState({} as User);
  const userContextValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  useTranslation();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TraceProvider>
        <AuthProvider {...oidcConfig}>
          <ThemeProvider theme={theme}>
            <UserContext.Provider value={userContextValue}>
              <CssBaseline />
              <Router>
                <Routes>
                  <Route path='/' element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path='drapery' element={<PlayerDrapery />} />
                    <Route path='characters' element={<PlayerCharacters />} />
                    <Route path='npcs' element={<NonPlayerCharacters />} />
                    <Route path='rules' element={<Rules />} />
                    <Route path='rules/drapery' element={<RulesDrapery />} />
                    <Route path='rules/it' element={<RulesIT />} />
                    <Route
                      path='rules/world-building'
                      element={<RulesWorld />}
                    />
                    <Route
                      path='approval/characters'
                      element={<CharacterApproval />}
                    />
                    <Route
                      path='approval/drapery'
                      element={<DraperyApproval />}
                    />
                    <Route
                      path='approval/signups'
                      element={<SignupApproval />}
                    />
                    <Route path='admin' element={<Admin />} />
                    <Route path='clubs' element={<Clubs />} />
                    <Route
                      path='/rules/code-of-conduct'
                      element={<CodeOfConduct />}
                    />
                    <Route path='/register' element={<Register />} />
                    <Route path='/signup' element={<Signup />} />
                    <Route path='/login' element={<LandingPage />} />
                    <Route path='/waiting-list' element={<WaitingList />} />
                    <Route
                      path='/privacy'
                      element={<PrivacyImprint name='privacy' />}
                    />
                    <Route
                      path='/imprint'
                      element={<PrivacyImprint name='imprint' />}
                    />
                    <Route path='*' element={<NotFound />} />
                  </Route>
                </Routes>
              </Router>
            </UserContext.Provider>
          </ThemeProvider>
        </AuthProvider>
      </TraceProvider>
    </Suspense>
  );
}
