import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { Button } from 'components/ui';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
  const nav = useNavigate();

  return (
    <Box
      sx={{
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80vw',
        height: '70vh',
        gap: '50px',
      }}
    >
      <Typography
        variant='h1'
        sx={{
          fontSize: '3rem',
          fontWeight: '700',
        }}
      >
        {t('Components.NotFound.Title')}
      </Typography>

      <Typography
        variant='body2'
        sx={{
          fontSize: '2rem',
          fontWeight: '500',
          textAlign: 'center',
          '@media(max-width: 600px)': {
            fontSize: '1.5rem',
          },
        }}
      >
        {t('Components.NotFound.Caption')}
      </Typography>
      <Button
        className='landing-btn'
        color='secondary'
        onClick={() => nav(-1)}
        sx={{
          width: '260px',

          fontSize: '25px',
          letterSpacing: '1px',
          padding: '0px',
        }}
        text={t('Components.NotFound.Button')}
      />
    </Box>
  );
}
