import { Box } from '@mui/material';

import { ClubDataGrid } from '../ClubDataGrid';

export function AdminClubs() {
  return (
    <Box className='content' sx={{ margin: '20px auto 0 auto', width: '100%' }}>
      <ClubDataGrid />
    </Box>
  );
}
