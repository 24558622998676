import { ReactNode, useState, FocusEvent } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  Select,
} from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { File } from 'buffer';
import { CloudUpload } from '@mui/icons-material';

import { Button, Dialog, VisuallyHiddenInput } from 'components/ui';

import { HTTP_METHOD, PEOPLE_URL, TEXT_FIELD_BIG } from 'const';
import { Character, Profile } from 'types';
import { useFetchFromBackend, useSnackbar } from 'func';

type Props = {
  open: boolean;
  onClose: () => void;
  fetchCallback: () => void;
  profile: Profile;
  characters: Character[];
};

export function DraperyNew(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();

  const [loading, setLoading] = useState<boolean>(false);

  const [char, setChar] = useState<string>();
  const [note, setNote] = useState<string>();
  const [file, setFile] = useState<File>();

  const { showSnackbar } = useSnackbar();

  const onSave = () => {
    setLoading(true);
    const body = new FormData();
    body.append('uploadFile', file);
    const url = `${PEOPLE_URL}/${char}/drapery?note=${note}`;

    fetchFromBackend(url, { method: HTTP_METHOD.POST, body })
      .then((response) => {
        const severity = response.status < 400 ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          props.fetchCallback();
        }

        showSnackbar(`${t('Components.Drapery.Save')} ${result}`, severity);
      })
      .finally(() => {
        setLoading(false);
        props.onClose();
      });
  };

  const onFileChange = (file) => {
    setFile(file);
  };

  function actions(): ReactNode {
    if (loading) return <></>;

    return (
      <Button
        onClick={onSave}
        color='success'
        sx={{ marginLeft: '5px' }}
        disabled={!file}
        text={<Trans i18nKey='Components.Drapery.Save' />}
      />
    );
  }

  function content(): ReactNode {
    if (loading) {
      return (
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Grid
        container
        spacing={2}
        sx={{
          label: {
            color: 'rgba(255, 255, 255, 0.7)',
            fontFamily: 'Neuton',
          },
          input: {
            color: '#ffffff',
            fontFamily: 'Neuton',
          },
          textarea: {
            color: '#ffffff',
            fontFamily: 'Neuton',
          },
          '& .MuiInput-root::after': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
          },
          '& .MuiInput-root:hover': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
          },
          '& .MuiInput-root': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
          },
        }}
      >
        <Grid item xs={12}>
          <InputLabel id='selection-label'>
            {t('Components.Drapery.WhichChar')}
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Select
            id='character'
            label={t('Components.Drapery.WhichChar')}
            value={char ?? ''}
            fullWidth
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'primary.main',
              },
            }}
            MenuProps={{
              slotProps: {
                paper: {
                  style: {
                    backgroundColor: '#1f1f1f',
                  },
                },
              },
            }}
            onChange={(elem) => setChar(elem.target.value)}
            variant='standard'
          >
            {props.characters?.map((c) => (
              <MenuItem
                key={c.id}
                value={c.id}
                sx={{
                  backgroundColor: '#1e1e1e',
                  color: '#fff',
                  fontFamily: 'Neuton',
                  '&:hover': {
                    backgroundColor: '#303030',
                  },
                }}
              >
                <ListItemText primary={c.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <InputLabel
            id='selection-label'
            sx={{
              justifyContent: 'left',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {t('Components.Drapery.Note')}
          </InputLabel>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id='note'
            variant='standard'
            label={t('Generic.Note')}
            sx={{ border: '1px solid #fff' }}
            multiline
            fullWidth
            inputProps={{
              maxLength: TEXT_FIELD_BIG,
            }}
            rows={10}
            onChange={(
              event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setNote(event.target.value)}
          />
          <Typography>
            ({note?.length}/{TEXT_FIELD_BIG})
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <InputLabel
            id='selection-label'
            sx={{
              justifyContent: 'left',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {t('Components.Drapery.File')}
          </InputLabel>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            '& .MuiButtonBase-root': {
              color: 'black',
              fontFamily: 'Bebas',
            },
          }}
        >
          {file && (
            <Typography variant='body1'>
              {t('Generic.File')}: {file?.name}
            </Typography>
          )}
          <Button
            component='label'
            role={undefined}
            tabIndex={-1}
            fullWidth
            startIcon={<CloudUpload />}
            sx={{
              justifyContent: 'left',
            }}
            text={
              <>
                {t('Generic.Upload')}
                <VisuallyHiddenInput
                  type='file'
                  onChange={(event) => onFileChange(event.target.files[0])}
                  accept='image/*'
                />
              </>
            }
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={`${t('Components.Drapery.New')} - ${props.profile?.nickname}`}
      actions={actions()}
      content={content()}
    />
  );
}
