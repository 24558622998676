import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Button, Dialog } from 'components/ui';
import { CLUBS_URL, EMPTY, HTTP_METHOD, USERS_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Character, Club, Roles, User } from 'types';
import { ClubEditDialog } from '../ClubEditDialog';
import { useAuth } from 'react-oidc-context';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { DeleteDialog } from '../DeleteDialog';
import { ThestralDataGrid } from '../ThestralDataGrid';
import { CharacterContext } from 'contexts';
import { PlayerCharacterCard } from '../PlayerCharacterCard';
import { ProfileDialog } from '../ProfileDialog';

type Props = {
  open: boolean;
  onClose: () => void;
  triggerRefetch?: () => void;
  club?: Club;
};

export function ClubInfoDialog(props: Readonly<Props>) {
  const auth = useAuth();
  const email = auth.user?.profile.email;
  const { showSnackbar } = useSnackbar();

  const { data: user } = useFetch<User>(`${USERS_URL}/by-email/${email}`);

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [char, setChar] = useState<Character>();
  const [openMember, setOpenMember] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [club, setClub] = useState<Club>();

  const charContextValue = useMemo(() => ({ char, setChar }), [char, setChar]);
  const fetchFromBackend = useFetchFromBackend();

  const {
    admins: clubLeader,
    members: clubMember,
    teacher: clubTeacher,
    description: clubDescription,
  } = club ?? {
    clubLeader: [{}, {}, {}],
    clubMember: [{}, {}, {}],
    clubTeacher: { name: 'Kein Name' },
    clubDescription: '',
  };

  const memberIds = clubMember?.map((member) => member.userId);
  const isMember = memberIds?.includes(user?.id);
  const isLeader = hasLeaderRights(clubLeader || []);
  const isAdmin = hasAnyRole(['ADMIN']);

  useEffect(() => {
    if (props?.club) {
      setClub(props.club);
    }
  }, [props.club]);

  const handleClose = () => {
    setEditOpen(false);
  };

  function hasLeaderRights(clubLeader) {
    if (auth.isAuthenticated) {
      const leaderIds = clubLeader?.map((leader) => leader.userId);

      return leaderIds?.includes(user?.id);
    }
  }

  function hasAnyRole(allowed: Array<string>) {
    if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.some((r) => allowed.includes(r)) ?? false;
    }

    return true;
  }

  function onDelete(event, member: Character) {
    event.stopPropagation();
    setDeleteOpen(true);
    setChar(member);
  }

  const onRowClick = (elem) => {
    setChar(elem);
    setOpenMember(true);
  };

  async function toggleJoin() {
    const url = isMember ? 'leave' : 'join';
    const body = JSON.stringify(user?.id);
    const method = HTTP_METHOD.POST;
    const snackbar =
      url === 'join'
        ? `${t('Components.Admin.Clubs.Club')} ${t(
            'Components.Admin.Clubs.Join'
          )}`
        : `${t('Components.Admin.Clubs.Club')} ${t(
            'Components.Admin.Clubs.Leave'
          )}`;

    try {
      const response = await fetchFromBackend(
        `${CLUBS_URL}/${props.club?.id}/${url}`,
        { body, method }
      );
      const severity = response.ok ? 'success' : 'error';
      const result = response.ok
        ? t('Generic.Successful')
        : `${t('Generic.Failed')} (${response.status})`;

      if (response.ok) {
        props.triggerRefetch();
      }

      showSnackbar(`${snackbar} ${result}`, severity);
    } catch (error) {
      showSnackbar(`${snackbar} ${t('Generic.Failed')}`, 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {editOpen && (
        <ClubEditDialog
          open={editOpen}
          onClose={handleClose}
          club={props.club}
          isAdmin={isAdmin}
          isLeader={isLeader}
          setEditOpen={setEditOpen}
          triggerRefetch={props?.triggerRefetch}
        />
      )}

      {char && (
        <DeleteDialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          triggerRefetch={props?.triggerRefetch}
          endpoint={`${CLUBS_URL}/${props.club?.id}/kick`}
          i18nBase='Components.Admin.Clubs.Member'
          method={HTTP_METHOD.POST}
          body={JSON.stringify(char.id)}
        />
      )}

      {char && openMember && (
        <CharacterContext.Provider value={charContextValue}>
          <Dialog
            sx={{
              '& .MuiPaper-root': {
                bgcolor: '#1e1e1e',
                marginBottom: '0',
              },
            }}
            open={openMember}
            onClose={() => setOpenMember(false)}
            title={char.name}
            content={<PlayerCharacterCard readonly />}
            titleButton={
              char?.profileVisibility === 'FORALL' && (
                <Button
                  text='Profil'
                  color='secondary'
                  onClick={() => setProfileOpen(true)}
                />
              )
            }
          />
        </CharacterContext.Provider>
      )}

      {profileOpen && char && (
        <ProfileDialog
          open={profileOpen}
          onClose={() => setProfileOpen(false)}
          id={char?.userId}
          noDataProtection
          public
        />
      )}

      <Dialog
        open={props.open}
        onClose={props.onClose}
        content={
          <Grid container gap={4}>
            {!isLeader && (
              <Button
                onClick={() => toggleJoin()}
                color={isMember ? 'error' : 'success'}
                text={
                  isMember
                    ? t('Components.Admin.Clubs.Leave')
                    : t('Components.Admin.Clubs.Join')
                }
                sx={{
                  marginBottom: '10px',
                  position: 'absolute',
                  top: ' 20px',
                  right: '20px',
                }}
                disabled={loading}
              />
            )}
            <Grid xs={12} md={4}>
              <Grid xs={12} sx={{ marginBottom: '30px' }}>
                <Typography
                  variant='body2'
                  sx={{ fontSize: '1rem', marginBottom: '20px' }}
                >
                  {t('Components.Admin.Clubs.Leader')}
                </Typography>
                <ThestralDataGrid
                  rows={clubLeader || EMPTY}
                  columnVisibilityModel={{
                    actions: isAdmin,
                  }}
                  columns={[
                    {
                      field: 'name',
                      headerName: t('Components.PlayerCharacters.Name'),
                      flex: 2,
                      sortable: true,
                    },
                    {
                      field: 'ageGroup',
                      headerName: t('Components.PlayerCharacters.AgeGroup'),
                      flex: 1,
                      sortable: true,
                      valueGetter: (_, row) =>
                        clubMember
                          ? t('Enum.AgeGroup.' + `${row.ageGroup}`)
                          : '',
                    },
                    {
                      field: 'house',
                      headerName: t('Components.PlayerCharacters.House'),
                      sortable: true,
                      flex: 1,
                      align: 'right',
                      headerAlign: 'right',
                    },
                  ]}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  hideSearch
                  hideFooter
                  hideFooterPagination
                  pageSizeOptions={[5, 10]}
                  onRowClick={onRowClick}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={4}>
              <Typography
                variant='body2'
                sx={{ fontSize: '1rem', marginBottom: '20px' }}
              >
                {t('Components.Admin.Clubs.Members')}
              </Typography>
              <Grid xs={12} sx={{ marginBottom: '20px' }}>
                <ThestralDataGrid
                  rows={clubMember || EMPTY}
                  columnVisibilityModel={{
                    actions: isLeader,
                  }}
                  columns={[
                    {
                      field: 'name',
                      headerName: t('Components.PlayerCharacters.Name'),
                      flex: 2,
                      sortable: true,
                    },
                    {
                      field: 'ageGroup',
                      headerName: t('Components.PlayerCharacters.AgeGroup'),
                      flex: 1,
                      sortable: true,
                      valueGetter: (_, row) =>
                        clubMember
                          ? t('Enum.AgeGroup.' + `${row.ageGroup}`)
                          : '',
                    },
                    {
                      field: 'house',
                      headerName: t('Components.PlayerCharacters.House'),
                      sortable: true,
                      flex: 1,
                      align: 'right',
                      headerAlign: 'right',
                    },
                    {
                      field: 'actions',
                      headerName: 'Aktionen',
                      flex: 1,
                      align: 'right',
                      headerAlign: 'right',
                      renderCell: (params: GridRenderCellParams) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'center',
                            justifyContent: 'end',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <IconButton
                            onClick={(event) => onDelete(event, params.row)}
                          >
                            <Tooltip title={t('Components.Admin.Clubs.Delete')}>
                              <Delete color='primary' />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      ),
                    },
                  ]}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  rowHeight={48}
                  hideSearch
                  hideFooter
                  hideFooterPagination
                  pageSizeOptions={[5, 10]}
                  onRowClick={onRowClick}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={3}>
              <Typography
                variant='body2'
                sx={{ fontSize: '1rem', marginBottom: '10px' }}
              >
                {t('Components.Admin.Clubs.Teacher')}
              </Typography>
              <Typography variant='caption'>{clubTeacher?.name}</Typography>
            </Grid>

            <Grid xs={12}>
              <Typography
                variant='body2'
                sx={{ fontSize: '1rem', marginBottom: '10px' }}
              >
                {t('Generic.Description')}
              </Typography>
              <Typography variant='caption'>{clubDescription}</Typography>
            </Grid>
          </Grid>
        }
        actions={
          isAdmin || isLeader ? (
            <Button
              onClick={() => setEditOpen(true)}
              color='primary'
              text={<Trans i18nKey='Generic.Edit' />}
            />
          ) : null
        }
        title={
          props.club ? props.club.name : t('Components.Admin.Clubs.ClubNew')
        }
      />
    </>
  );
}
